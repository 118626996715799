

/* We add top margin between the content and the back to news link */
.actualite.container > div {
	margin-top:20px;
}
.actualite .entry-title h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.actualite .entry-image img {
	max-height:600px;
	width:auto;
}
.actualite .entry-content h2 {
	font-size:1.1rem;
	margin-bottom: 15px;
}
.actualite .entry-content .media {
	display:block;
	max-width:800px;
}
.actualite .entry-content h3 {
	margin-top: 30px;
	margin-bottom: 20px;
}
.actualite .entry-content p {
	margin-bottom: 15px;
}
.actualite .entry-content ul {
	margin-left: 20px;
}

/* We add some margin between share title and icons */
.actualite .si-share span {
    margin-right: 10px;
}
.actualite .si-share .social-icon {
    border-radius: 3px;
    margin: 0 5px 0 0
}


